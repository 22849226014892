<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- DESA -->
      <fieldset style="border: 1px solid #dee2e6; padding: 8px" class="mb-3">
        <legend
          style="
            border: 1px solid #dee2e6;
            padding: 9px;
            margin-left: 13px;
            font-size: initial;
            width: initial;
            background: white;
          "
        >
          Desa
        </legend>

        <b-form-group
          id="input-group-village_name"
          label="Nama Desa:"
          label-for="input-village_name"
        >
          <b-form-input
            id="input-village_name"
            v-model="form.village_name"
            placeholder="Nama Desa"
          ></b-form-input>
          <small class="text-danger">{{ error.village_name }}</small>
        </b-form-group>

        <div class="row mb-0">
          <div class="col mb-0 pb-0">
            <b-form-group
              id="input-group-city"
              label="Kota/Kabupaten:"
              label-for="input-city"
            >
              <b-form-input
                id="input-city"
                v-model="form.city"
                placeholder="Kota/Kabupaten"
              ></b-form-input>
              <small class="text-danger">{{ error.city }}</small>
            </b-form-group>
          </div>
          <div class="col mb-0 pb-0">
            <b-form-group
              id="input-group-district"
              label="Kecamatan:"
              label-for="input-district"
            >
              <b-form-input
                id="input-district"
                v-model="form.district"
                placeholder="Kecamatan"
              ></b-form-input>
              <small class="text-danger">{{ error.district }}</small>
            </b-form-group>
          </div>
          <div class="col mb-0 pb-0">
            <b-form-group
              id="input-group-postal_code"
              label="Kode Pos:"
              label-for="input-postal_code"
            >
              <b-form-input
                id="input-postal_code"
                v-model="form.postal_code"
                placeholder="Kode Pos"
              ></b-form-input>
              <small class="text-danger">{{ error.postal_code }}</small>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="input-group-address">
          <label for="input-address">Alamat Kantor Desa: </label>
          <b-form-textarea
            id="input-address"
            v-model="form.address"
            placeholder="Alamat Kantor Desa"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.address }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-village_email"
          label="Email Desa:"
          label-for="input-village_email"
        >
          <b-form-input
            id="input-village_email"
            v-model="form.village_email"
            placeholder="Email Desa"
          ></b-form-input>
          <small class="text-danger">{{ error.village_email }}</small>
        </b-form-group>

        <b-form-group id="input-group-web_url">
          <label for="input-web_url"
            >URL Website Desa: <em class="text-muted">Opsional</em></label
          >
          <b-form-input
            id="input-web_url"
            v-model="form.web_url"
            placeholder="URL Website"
          ></b-form-input>
          <small class="text-danger">{{ error.web_url }}</small>
        </b-form-group>
      </fieldset>

      <!-- KEPALA DESA -->
      <fieldset style="border: 1px solid #dee2e6; padding: 8px" class="mb-3">
        <legend
          style="
            border: 1px solid #dee2e6;
            padding: 9px;
            margin-left: 13px;
            font-size: initial;
            width: initial;
            background: white;
          "
        >
          Kepala Desa
        </legend>
        <div class="row">
          <div class="col-md-4">
            <div
              class="
                d-flex
                justify-content-start
                align-items-center
                image-container
              "
            >
              <div style="height: 200px; width: 200px; position: relative">
                <img
                  class="image"
                  :src="[
                    uploadedVillageChiefPhotoUrl == ''
                      ? form.village_chief_photo
                      : uploadedVillageChiefPhotoUrl,
                  ]"
                  style="width: 100%; height: 100%; object-fit: contain"
                />
              </div>
            </div>
            <b-form-file
              v-model="form.village_chief_photo"
              @change="onVillageChiefPhotoUpload"
              placeholder="Unggah Foto Kepala Desa"
              drop-placeholder="Jatuhkan file disini..."
              class="mt-3"
              plain
            ></b-form-file>
          </div>
          <div class="col">
            <b-form-group
              id="input-group-village_chief_name"
              label="Nama Kepala Desa:"
              label-for="input-village_chief_name"
            >
              <b-form-input
                id="input-village_chief_name"
                v-model="form.village_chief_name"
                placeholder="Nama Kepala Desa"
              ></b-form-input>
              <small class="text-danger">{{ error.village_chief_name }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-village_chief_reg_number"
              label="NIP Kepala Desa:"
              label-for="input-village_chief_reg_number"
            >
              <b-form-input
                id="input-village_chief_reg_number"
                v-model="form.village_chief_reg_number"
                placeholder="NIP Kepala Desa"
              ></b-form-input>
              <small class="text-danger">{{
                error.village_chief_reg_number
              }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-village_chief_phone"
              label="No. WhatsApp Kepala Desa:"
              label-for="input-village_chief_phone"
            >
              <b-form-input
                id="input-village_chief_phone"
                v-model="form.village_chief_phone"
                placeholder="No. WhatsApp Kepala Desa"
              ></b-form-input>
              <small class="text-danger">{{ error.village_chief_phone }}</small>
            </b-form-group>
          </div>
        </div>
      </fieldset>

      <!-- WAKIL KEPALA DESA -->
      <fieldset style="border: 1px solid #dee2e6; padding: 8px" class="mb-3">
        <legend
          style="
            border: 1px solid #dee2e6;
            padding: 9px;
            margin-left: 13px;
            font-size: initial;
            width: initial;
            background: white;
          "
        >
          Wakil Kepala Desa
        </legend>
        <div class="row">
          <div class="col-md-4">
            <div
              class="
                d-flex
                justify-content-start
                align-items-center
                image-container
              "
            >
              <div style="height: 200px; width: 200px; position: relative">
                <img
                  class="image"
                  :src="[
                    uploadedDeputyVillageChiefPhotoUrl == ''
                      ? form.deputy_village_chief_photo
                      : uploadedDeputyVillageChiefPhotoUrl,
                  ]"
                  style="width: 100%; height: 100%; object-fit: contain"
                />
              </div>
            </div>
            <b-form-file
              v-model="form.deputy_village_chief_photo"
              @change="onDeputyVillageChiefPhotoUpload"
              placeholder="Unggah Foto Wakil Kepala Desa"
              drop-placeholder="Jatuhkan file disini..."
              class="mt-3"
              plain
            ></b-form-file>
          </div>
          <div class="col">
            <b-form-group
              id="input-group-deputy_village_chief_name"
              label="Nama Wakil Kepala Desa:"
              label-for="input-deputy_village_chief_name"
            >
              <b-form-input
                id="input-deputy_village_chief_name"
                v-model="form.deputy_village_chief_name"
                placeholder="Nama Wakil Kepala Desa"
              ></b-form-input>
              <small class="text-danger">{{
                error.deputy_village_chief_name
              }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-deputy_village_chief_reg_number"
              label="NIP Wakil Kepala Desa:"
              label-for="input-deputy_village_chief_reg_number"
            >
              <b-form-input
                id="input-deputy_village_chief_reg_number"
                v-model="form.deputy_village_chief_reg_number"
                placeholder="NIP Wakil Kepala Desa"
              ></b-form-input>
              <small class="text-danger">{{
                error.deputy_village_chief_reg_number
              }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-deputy_village_chief_phone"
              label="No. WhatsApp Wakil Kepala Desa:"
              label-for="input-deputy_village_chief_phone"
            >
              <b-form-input
                id="input-deputy_village_chief_phone"
                v-model="form.deputy_village_chief_phone"
                placeholder="No. WhatsApp Wakil Kepala Desa"
              ></b-form-input>
              <small class="text-danger">{{
                error.deputy_village_chief_phone
              }}</small>
            </b-form-group>
          </div>
        </div>
      </fieldset>

      <div class="row mt-5">
        <div class="col mt-0 pt-0">
          <b-form-group
            id="input-group-district_head_name"
            label="Nama Camat:"
            label-for="input-district_head_name"
          >
            <b-form-input
              id="input-district_head_name"
              v-model="form.district_head_name"
              placeholder="Nama Camat"
            ></b-form-input>
            <small class="text-danger">{{ error.district_head_name }}</small>
          </b-form-group>
        </div>
        <div class="col mt-0 pt-0">
          <b-form-group
            id="input-group-district_head_reg_number"
            label="NIP Camat:"
            label-for="input-district_head_reg_number"
          >
            <b-form-input
              id="input-district_head_reg_number"
              v-model="form.district_head_reg_number"
              placeholder="NIP Camat"
            ></b-form-input>
            <small class="text-danger">{{
              error.district_head_reg_number
            }}</small>
          </b-form-group>
        </div>
      </div>

      <b-form-group id="input-group-letter_opening_sentences">
        <label for="input-letter_opening_sentences">Kata Pembuka Surat: </label>
        <b-form-textarea
          id="input-letter_opening_sentences"
          v-model="form.letter_opening_sentences"
          placeholder="Kata Pembuka Surat"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.letter_opening_sentences }}</small>
      </b-form-group>

      <b-form-group id="input-group-letter_closing_sentences">
        <label for="input-letter_closing_sentences">Kata Penutup Surat: </label>
        <b-form-textarea
          id="input-letter_closing_sentences"
          v-model="form.letter_closing_sentences"
          placeholder="Kata Penutup Surat"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.letter_closing_sentences }}</small>
      </b-form-group>

      <div class="row">
        <div class="col mb-3">
          <label for="">Logo:</label>
          <div
            class="
              d-flex
              justify-content-start
              align-items-center
              image-container
            "
          >
            <div style="height: 200px; width: 200px; position: relative">
              <img
                class="image"
                :src="[
                  uploadedLogoUrl == '' ? form.city_logo : uploadedLogoUrl,
                ]"
                style="width: 100%; height: 100%; object-fit: contain"
              />
            </div>
          </div>
          <b-form-file
            v-model="form.city_logo"
            @change="onLogoUpload"
            placeholder="Upload Logo"
            drop-placeholder="Drop file here..."
            class="mt-3"
            plain
          ></b-form-file>
        </div>
        <div class="col mb-3">
          <label for=""
            >Gambar Halaman Login: <em class="text-muted">(698x961)</em></label
          >
          <div
            class="
              d-flex
              justify-content-start
              align-items-center
              image-container
            "
          >
            <div style="height: 200px; width: 200px; position: relative">
              <img
                class="image"
                :src="[
                  uploadedCoverLoginUrl == ''
                    ? form.cover_login
                    : uploadedCoverLoginUrl,
                ]"
                style="width: 100%; height: 100%; object-fit: contain"
              />
            </div>
          </div>
          <b-form-file
            v-model="form.cover_login"
            @change="onCoverLoginUpload"
            placeholder="Upload Cover Logo"
            drop-placeholder="Drop file here..."
            class="mt-3"
            plain
          ></b-form-file>
        </div>
      </div>

      <b-form-group id="input-group-running_text">
        <label for="input-running_text">Teks berjalan: </label>
        <b-form-textarea
          id="input-running_text"
          v-model="form.running_text"
          placeholder="Teks berjalan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.running_text }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/medicine-categories')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        village_name: "",
        village_email: "",
        village_chief_name: "",
        village_chief_reg_number: "",
        village_chief_photo: "",
        village_chief_phone: "",
        deputy_village_chief_name: "",
        deputy_village_chief_reg_number: "",
        deputy_village_chief_photo: "",
        deputy_village_chief_phone: "",
        postal_code: "",
        city: "",
        district: "",
        web_url: "",
        address: "",
        city_logo: "",
        district_head_reg_number: "",
        district_head_name: "",
        cover_login: "",
        running_text: "",
      },
      uploadedLogoUrl: "",
      uploadedCoverLoginUrl: "",
      uploadedVillageChiefPhotoUrl: "",
      uploadedDeputyVillageChiefPhotoUrl: "",
    };
  },

  methods: {
    onLogoUpload(evt) {
      this.uploadedLogoUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.city_logo = evt.target.files[0];
      console.log("city_logo", this.form.city_logo);
    },

    onCoverLoginUpload(evt) {
      this.uploadedCoverLoginUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.cover_login = evt.target.files[0];
      console.log("cover_login", this.form.cover_login);
    },

    onVillageChiefPhotoUpload(evt) {
      this.uploadedVillageChiefPhotoUrl = URL.createObjectURL(
        evt.target.files[0]
      );
      this.form.village_chief_photo = evt.target.files[0];
      console.log("village_chief_photo", this.form.village_chief_photo);
    },

    onDeputyVillageChiefPhotoUpload(evt) {
      this.uploadedDeputyVillageChiefPhotoUrl = URL.createObjectURL(
        evt.target.files[0]
      );
      this.form.deputy_village_chief_photo = evt.target.files[0];
      console.log(
        "deputy_village_chief_photo",
        this.form.deputy_village_chief_photo
      );
    },

    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        // this.$router.push('/masters/urban-village')
      }
    },
  },
};
</script>

<style>
</style>